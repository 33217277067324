import React from 'react';
import { Checkbox } from '@mui/material';
import styles from './styled-checkbox.module.scss';

function StyledCheckbox(props) {
  return (
    <Checkbox
      disableRipple={props.disableRippleProp}
      disableTouchRipple={props.disableTouchRippleProp}
      disableFocusRipple={props.disableFocusRippleProp}
      checked={props.checkedProp}
      disabled={props.disabledProp}
      icon={props.iconProp}
      name={props.nameProp}
      checkedIcon={props.checkedIconProp}
      value={props.valueProp}
      onChange={(event) => {
        props?.onChangeProp &&
          props.onChangeProp(event, props.onChangePropObject);
      }}
      className={styles[props.classNameProp]}
      inputProps={props.inputPropsProp}
      {...props}
    />
  );
}

export default StyledCheckbox;
