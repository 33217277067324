import React, { useEffect, useState } from 'react';
import { Grid, Container, Button, TextField, IconButton, Box } from '@mui/material';
import styles from './product-guide-video-search.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import FilterLeftPanel from '../FilterLeftPanel';
import ProductCardGrid from '../ProductCardGrid';
import i18next from 'i18next';
import Images from '../../../assets/img';
import DeviceBreakPoints from '../../../deviceBreakPoints';

const ProductGuideVideoSearch = (props) => {
    const { isMediumDevice, isSmallDevice, theme } = DeviceBreakPoints();
    const [displayFilter, setDisplayFilter] = useState(true);

    const filterBox = () => {
        setDisplayFilter(true);
    };

    const closeFilter = () => {
        setDisplayFilter(false);
    };

    useEffect(() => {
        if (!(isMediumDevice || isSmallDevice)) {
            setDisplayFilter(true);
        } else {
            setDisplayFilter(false);
        }
    }, [isMediumDevice, isSmallDevice]);

    return (
        <Container>
            <Grid container md={12} sm={12} xs={12} className={styles['productguide-grid']}>
                <Grid item className={styles['productguide-search']} >
                    <TextField
                        className='search-field'
                        variant='filled'
                        sx={{ input: { fontSize: '0.9rem !important' } }}
                        placeholder={i18next.t('FILTER_SECTION.SEARCH_TEXT')}
                        disabled={true}
                        InputProps={{
                            endAdornment: (
                                <IconButton disabled={true} aria-label='search' size='small' className={styles['search-btn']}>
                                    <SearchIcon fontSize='inherit' />
                                </IconButton>),
                        }}
                    />
                </Grid>
                <Grid item className={styles['buttons-grid']}>
                    {(isMediumDevice || isSmallDevice) &&
                        <Button className={styles['action-button-item']} sx={{color: `${theme.palette.common.dark} !important`, backgroundColor: `${theme.palette.common.light} !important`}} onClick={filterBox} >
                            {i18next.t('FILTER_SECTION.FILTER')}
                            <Box component='img' src={Images.categories.filter} className={styles['sort-icon']} />
                        </Button>
                    }
                    <Button disabled={true} className={styles['action-button-item']} sx={{color: `${theme.palette.common.dark} !important`, backgroundColor: `${theme.palette.common.light} !important`}}>
                        {i18next.t('FILTER_SECTION.SORT_BY')}
                        <Box component='img' src={Images.sortIcon} className={styles['sort-icon']} />
                    </Button>
                </Grid>
            </Grid>
            <Grid container className={styles['filter-guides-grid']}  >
                {displayFilter &&
                    <Grid item className={(isMediumDevice || isSmallDevice) ? styles['filter-panel-box-mobile'] : styles['filter-panel-box']}>
                        <FilterLeftPanel closeFilter={closeFilter} />
                    </Grid>}
                <Grid item className={(isMediumDevice || isSmallDevice) ? styles['product-guide-card-box-mobile'] : styles['product-guide-card-box']}>
                    <ProductCardGrid redirected={props.redirected} />
                </Grid>
            </Grid>
        </Container>
    );

};

export default ProductGuideVideoSearch;
