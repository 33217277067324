import React from 'react';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Box, Typography } from '@mui/material';
import styles from './breadcrumb.module.scss';

const useStyles = makeStyles({
  breadcrumbOuterDiv: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

export default function Breadcrumb(props) {
  const classes = useStyles();
  return (
    <Box className={classes.breadcrumbOuterDiv}>
      <Breadcrumbs
        separator={<Typography variant='p' className={`${styles['separator-style']}`}><ArrowForwardIosOutlinedIcon sx={{ width: '0.5em !important', height: '0.5em !important', opacity: '0.5 !important' }} fontSize='small' /></Typography>}
        item={NavLink}
        finalProps={{
          style: {
            textDecoration: 'none',
            color: 'common.dark',
            cursor: 'initial',
            pointerEvents: 'none'   //to prevent crashing when clicked on last item in breadcrumb (in case of product pages)
          }
        }}
        compare={(firstItem, secondItem) => firstItem.weight - secondItem.weight}  // to not let breadcrumbs sort items by its own       
      />
      {props.children}
    </Box>
  );
}