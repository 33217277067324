const initialState = {
  orderDetails: null,
  pspPaymentFailureResponse: null,
  pspPaymentSuccessResponse: null,
  orderSummaryDetails: {
    checkoutId: null,
    previousCheckoutId: null,
    orderPlaced: false
  },
};

const OrderSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CHECKOUT_ID':
      return {
        ...state,
        orderSummaryDetails: {
          ...state.orderSummaryDetails,
          checkoutId: action.payload
        }
      };
    case 'UPDATE_ORDER_DETAILS':
      return { ...state, orderDetails: action.payload };
    case 'CLEAR_ORDER_DETAILS':
      return { ...state, orderDetails: action.payload };
    case 'UPDATE_PREVIOUS_CHECKOUT_ID':
      return {
        ...state,
        orderSummaryDetails: {
          ...state.orderSummaryDetails,
          previousCheckoutId: action.payload
        }
      };
    case 'UPDATE_PAYMENT_FAILURE_RESPONSE':
      return { ...state, pspPaymentFailureResponse: action.payload };
    case 'UPDATE_PAYMENT_SUCCESS_RESPONSE':
      return { ...state, pspPaymentSuccessResponse: action.payload };
    case 'UPDATE_ORDER_PLACED':
      return {
        ...state,
        orderSummaryDetails: {
          ...state.orderSummaryDetails,
          orderPlaced: action.payload
        }
      };
    case 'UPDATE_ORDER_SUMMARY_DETAILS':
      return {
        ...state,
        orderSummaryDetails: action.payload
      };
    default:
      return state;
  }
};
export default OrderSummaryReducer;