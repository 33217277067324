import React from 'react';
import SpriteImageAnimatedTextSlide from './SpriteImageAnimatedTextSlide/spriteImageAnimatedTextSlide';
// Ref# IT9.2 MS-14706 - Uncomment below imports when using carousel
/* import { Button } from '@mui/material';
import styles from './homepage-banner.module.scss';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import DeviceBreakPoints from '../../../deviceBreakPoints'; */

const HomepageBanner = (props) => {
  // const { isPhone, theme } = DeviceBreakPoints();
  return (
    <SpriteImageAnimatedTextSlide {...props}/>

    // Ref# IT9.2 MS-14706 - Comment below carousel and uncomment above line to show slide without carousel
    // <Carousel
    //   navButtonsAlwaysVisible={true}
    //   cycleNavigation={true}
    //   animation='slide'
    //   autoPlay={false}
    //   indicatorContainerProps={{
    //     className: isPhone
    //       ? styles['indicator-container-phone']
    //       : styles['indicator-container']
    //   }}
    //   indicatorIconButtonProps={{
    //     style: {
    //       color: theme.palette.secondary.dark,
    //       padding: '0 7px',
    //       paddingBottom: '15px',
    //       zIndex: 1
    //     }
    //   }}
    //   activeIndicatorIconButtonProps={{
    //     style: {
    //       color: theme.palette.primary.main,
    //       transform: 'scale(1.5)',
    //       marginTop: '7px'
    //     }
    //   }}
    //   NavButton={({ onClick, next, prev }) => {
    //     return (
    //       <Button sx={{ color: 'common.light', '&:hover': { backgroundColor: 'transparent !important' } }} onClick={onClick} className={styles['carousel-buttons']}>
    //         {next && <ArrowForwardIosOutlinedIcon />}
    //         {prev && <ArrowBackIosNewOutlinedIcon />}
    //       </Button>
    //     );
    //   }}
    // >
    //   <SpriteImageAnimatedTextSlide {...props} />
    //   <SpriteImageAnimatedTextSlide {...props} />
    //   <SpriteImageAnimatedTextSlide {...props} />
    // </Carousel>
  );
};

export default HomepageBanner;
