import axios from 'axios';
import * as Constants from './configuration';

export function setGetInTouchDetails(obj) {
    return (dispatch) =>
        dispatch({
            type: 'GET_IN_TOUCH_DETAILS',
            payload: obj,
        });
}

export function setGetInTouchDetailsError(obj) {
    return (dispatch) =>
        dispatch({
            type: 'GET_IN_TOUCH_DETAILS_ERR',
            payload: obj,
        });
}

export function postUserQuery(reqObject) {
    return (dispatch) =>
        axios({
            method: 'POST',
            url:
                Constants.CONTACT_US.USER_QUERY,
            data: reqObject,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                if (!!error.response) {
                    dispatch({
                        type: 'ERROR',
                        error: error.response,
                    });
                }
                else {
                    dispatch({
                        type: 'ERROR',
                        error: error,
                    });
                }
                return error?.response;
            });
}