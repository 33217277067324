import React from 'react';
import styles from './product-guide-video.module.scss';
import ProductGuideVideoSearch from './ProductGuideVideoSearch';
import BannerSection from '../Shared/BannerSection';
import { Grid } from '@mui/material';

const ProductGuideVideo = (props) => {
    return (
        <Grid sx={{ backgroundColor: 'secondaryBackground.bgBody' }} container className={`${styles['light-background']}`} > 
            <BannerSection {...props} redirected={props.redirected}  />
            <ProductGuideVideoSearch redirected={props.redirected}/>
        </Grid>
    );
};

export default ProductGuideVideo;