const LoaderAction = (bool) => {
  return bool ? {
    type: 'SHOW_LOADER',
    data: bool
  } : {
    type: 'HIDE_LOADER',
    data: bool
  };
};

export default LoaderAction;