const initialState = {
    validToken: false,
    user: {},
    errorLogin: null,
    userDetails: {},
    eulaAcceptance: true,
    acceptEulaResult: {},
    acceptEulaErr: null,
    forgotPassword: false,
    checkUserAgreement: '',
    showTermsConditions: false,
    showPrivacyPolicies: false,
    tokenInvalidated: false,
    refreshToken: '',
    accessToken: '',
    confirmModal: false
};

const booleanActionPayload = payload => Boolean(payload);


const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                validToken: booleanActionPayload(action.payload),
                user: action.payload
            };
        case 'ERROR_LOGIN':
            return Object.assign({}, state, { errorLogin: action.error });
        case 'SET_USER_DETAILS':
            return {
                ...state,
                userDetails: action.payload
            };
        case 'EULA_ACCEPTANCE':
            return Object.assign({}, state, { eulaAcceptance: action.payload });
        case 'ACCEPT_EULA_RESULT':
            return Object.assign({}, state, { acceptEulaResult: action.payload, acceptEulaErr: null });
        case 'ACCEPT_EULA_ERR':
            return Object.assign({}, state, { acceptEulaResult: {}, acceptEulaErr: action.error });
        case 'FORGOT_PASSWORD':
            return Object.assign({}, state, { forgotPassword: action.payload });
        case 'CHECK_USER_AGREEMENT':
            return { ...state, checkUserAgreement: action.payload };
        case 'SHOW_TERMS_CONDITIONS':
            return { ...state, showTermsConditions: action.payload };
        case 'SHOW_PRIVACY_POLICIES':
            return { ...state, showPrivacyPolicies: action.payload };
        case 'TOKEN_INVALIDATED':
            return { ...state, tokenInvalidated: action.payload };
        case 'REFRESH_TOKEN':
            return { ...state, refreshToken: action.payload };
        case 'ACCESS_TOKEN':
            return { ...state, accessToken: action.payload };
        case 'CONFIRM_MODAL':
            return { ...state, confirmModal: action.payload};
        default:
            return state;
    }
};

export default loginReducer;