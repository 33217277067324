const initialState = {
  pricingDetails: [],
  cartItems: [],
  selectedProductQty: 1,
  cartProducts: [],
  cartProductCount: 0,
  cartLoader: true,
  cartQuantityList: []
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CART':
      return {
        ...state,
        cartItems: action.payload.data,
        cartProductCount: action.payload.count
      };
    case 'SET_PRODUCT_QUANTITY':
      return { ...state, selectedProductQty: action.payload };
    case 'PRICING_DETAILS':
      return {
        ...state,
        pricingDetails: action.payload
      };
    case 'CART_SHOW_LOADER':
      return Object.assign({}, state, { cartLoader: action.payload });
    case 'CART_HIDE_LOADER':
      return Object.assign({}, state, { cartLoader: action.payload });
    case 'GET_CART_PRODUCT_QUANTITY':
      return {
        ...state,
        cartQuantityList: [...state.cartQuantityList, action.payload]
      };
    case 'ADD_OR_REMOVE_CART_QUANTITY':
      let cartArr = [];
      state.cartItems.forEach((cartOuterArr)=> {
        let cartProducts = [];
        cartOuterArr?.items?.forEach((cartProduct) => {
          if (
            action?.payload?.quantity > 0 &&
            cartProduct.planId === action?.payload?.planId
          ) {
            cartProducts.push({
              ...cartProduct,
              quantity: action?.payload?.quantity
            });
          } else {
            cartProducts.push({
              ...cartProduct
            });
          }
        });
        cartArr.push({
          ...cartOuterArr,
          items: cartProducts
        });
      });
      return Object.assign({}, state, {
        cartItems: cartArr
      });
    case 'RESET_CART':
      return {
        ...state,
        pricingDetails: [],
        cartItems: [],
        cartQuantityList: [],
        cartProducts: [],
        cartProductCount: 0,
        cartLoader: false
      };
    default:
      return state;
  }
};

export default CartReducer;
