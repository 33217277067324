import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DeviceBreakPoints = () => {
    const theme = useTheme();
    const isMediumDevice = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    const isMediumDeviceOnly = useMediaQuery(theme.breakpoints.only('md'));
    const isPhone = useMediaQuery(theme.breakpoints.only('xs'));
    const isMobileDevice = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('lg'));
    const isXlDevice = useMediaQuery(theme.breakpoints.only('xl'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isDesktopDevice = useMediaQuery(theme.breakpoints.up('lg'));
    const isSmallDeviceBetween = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isExtraSmallDeviceBetween = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMediumDeviceViewBetween = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    return { isMediumDevice, isSmallDevice, isPhone, isMediumDeviceOnly, isMobileDevice, isTablet, isXlDevice, xsScreen, smScreen, mdScreen, isDesktopDevice, isTab, isSmallDeviceBetween, isExtraSmallDeviceBetween, isMediumDeviceViewBetween, theme };
};

export default DeviceBreakPoints;