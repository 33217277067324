const initialState = {
    orderHistoryDetails: '',
    orderHistoryCategories: '',
    orderHistoryError: '',
    responseHeaders: '',
    activeSubscriptions: '',
    activeSubscriptionsError: '',
    mySubscriptions: [],
    mySubscriptionsError: '',
    unsubscribeProductPlanResponse: ''

};

const OrderHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ORDER_HISTORY_DETAILS':
            return Object.assign({}, state, { orderHistoryDetails: action.payload, orderHistoryError: '', responseHeaders: action.headers });
        case 'ORDER_HISTORY_CATEGORIES':
            return Object.assign({}, state, { orderHistoryCategories: action.payload });
        case 'ORDER_HISTORY_ERROR':
            return Object.assign({}, state, { orderHistoryError: action.payload });
        case 'ACTIVE_SUBSCRIPTIONS':
            return Object.assign({}, state, { activeSubscriptions: action.payload, responseHeaders: action.headers, orderIdSubscriptions: action.subscriptionpayload });
        case 'ACTIVE_SUBSCRIPTIONS_ERROR':
            return Object.assign({}, state, { activeSubscriptionsError: action.payload });
        case 'MY_SUBSCRIPTIONS':
            return Object.assign({}, state, { mySubscriptions: action.payload });
        case 'MY_SUBSCRIPTIONS_ERROR':
            return Object.assign({}, state, { mySubscriptionsError: action.payload });
        case 'UNSUBSCRIBE_PRODUCT_PLAN_RESPONSE':
            return Object.assign({}, state, { unsubscribeProductPlanResponse: action.payload });
        case 'RESET_UNSUBSCRIBE_PRODUCT_PLAN_RESPONSE':
            return Object.assign({}, state, { unsubscribeProductPlanResponse: action.payload });
        default:
            return state;
    }
};

export default OrderHistoryReducer;