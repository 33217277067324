import React from 'react';
import { Sticky } from 'react-sticky';
import { Box, Button } from '@mui/material';
import images from '../../../assets/img';
import styles from './sticky-icons.module.scss';
import { connect } from 'react-redux';
import { setGetInTouchDetails, setGetInTouchDetailsError, postUserQuery } from '../../../actions/GetInTouchAction';
import { getProductCategories, getAllProductsCount } from '../../../actions/LandingAction';
import { bindActionCreators } from 'redux';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';    // to be used when commented code below is enabled

const StickyIcons = (props) => {

    return (
        <Sticky>
            {
                () =>
                    <Box className={styles['icon-button-container']}>
                        <Button
                            sx={{
                                background: 'primaryBackground.main !important'
                            }}
                            variant='filledBtn'
                            className={styles['getintouch-button']}
                            href={'/get-in-touch'}
                            target = '_blank'
                        >
                            <Box component={'img'} src={images.mailSupportIcon} sx={{ height: '18px', width: '18px' }} />
                        </Button>
                        {/* To-do: Cart icon button to be enabled once cart page is ready   
                        `  <Button
                            sx={{ color: 'common.dark', backgroundColor : 'primaryBackground.bgBody', '&:hover' : {
                                    backgroundColor: 'primaryBackground.bgBody'
                                } }}
                            variant='contained'
                            classes={{ root: styles['cart-button'] }}
                            disabled
                        >
                            <ShoppingCartOutlinedIcon/>
                        </Button> */}
                        
                    </Box>
            }
        </Sticky>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setGetInTouchDetails,
            setGetInTouchDetailsError,
            postUserQuery,
            getProductCategories,
            getAllProductsCount
        },
        dispatch
    );
}

const mapStateToProps = (redux_state) => ({
    getInTouchDetails: redux_state.GetInTouchReducer.getInTouchDetails,
    getInTouchDetailsError: redux_state.GetInTouchReducer.getInTouchDetailsError,
    productCategories: redux_state.LandingReducer.productCategories,
    publishedProductsCounts: redux_state.LandingReducer.publishedProductsCounts
});

export default connect(mapStateToProps, mapDispatchToProps)(StickyIcons);