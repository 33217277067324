const initialState = {
  productMetaData: {}
};

const ManageProductsReducer = (state = initialState, action) => {
  if (action.type === 'GET_PRODUCT_META_DATA') {
    return Object.assign({}, state, { productMetaData: action.payload });
  } else {
    return state;
  }  
};

export default ManageProductsReducer;
