import React from 'react';
import { Card, Typography, CardContent, CardMedia } from '@mui/material';
import { withRouter } from 'react-router';
import styles from './you-may-also-like-card.module.scss';

const YouMayAlsoLikeCard = (props) => {
  
  const navigateTo = (link) => {
    if (!link) return;
    props?.history?.push({
      pathname: link + '/listing',
      state: { prePopulateFrom: {url: link}}
    });
  };

  return (
    <React.Fragment>
      <Card
        className={`${styles['card']} ${props.className}`}
        onClick={() => navigateTo(props?.cardLink)}
        sx={{backgroundColor:'primaryBackground.bgBody'}}
      >
        <CardContent className={styles['card-content-style']}>
          <CardMedia
            component='img'
            className={styles['svg-image']}
            image={props?.cardImage}
            alt={props?.cardTitle}
          />
          <Typography
            gutterBottom
            variant='h6'
            color='common.dark'
            className={styles['card-title']}
            component='div'
          >
            {props?.cardTitle}
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(YouMayAlsoLikeCard);
