const initialState = {
  quotationFormDetails: [],
  selectedProductsInfo: [],
  createQuotation: {},
  quotationFormJsonData: [],
  buyerDocumentConfig: {},
  buyerImageConfig: {},
  buyerQuotationImages: [],
  buyerQuotationDocuments: [],
  sellerQuotationDocuments: [],
  sellerQuotationImages: [],
  quotationBuyerAction: {},
  quotationPrice: {},
  quotationFormValues: {},
  quotationListingOpen: [],
  quotationListingPast: [],
  quotationResponse: [],
  contactSales: {}
};

const QuotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OPEN_QUOTATION_LISTING':
      return { ...state, quotationListingOpen: action.payload };
    case 'GET_PAST_QUOTATION_LISTING':
      return { ...state, quotationListingPast: action.payload };
    case 'GET_QUOTATION_RESPONSE':
      return {
        ...state,
        quotationResponse: action.payload
      };
    case 'SAVE_QUOTATION_FORM_DATA':
      return {
        ...state,
        quotationFormJsonData: action.payload
      };
    case 'CLEAR_QUOTATION_FORM_DATA':
      return {
        ...state,
        quotationFormJsonData: [],
        buyerQuotationImages: [],
        buyerQuotationDocuments: []
      };
    case 'QUOTATION_DETAILS':
      return {
        ...state,
        quotationFormDetails: action.payload
      };
    case 'SELECTED_PRODUCTS':
      return {
        ...state,
        selectedProductsInfo: action.payload
      };
    case 'CREATE_QUOTATION':
      return {
        ...state,
        createQuotation: action.payload
      };
    case 'BUYER_DOCUMENT_CONFIG':
      return {
        ...state,
        buyerDocumentConfig: action.payload
      };
    case 'BUYER_IMAGE_CONFIG':
      return {
        ...state,
        buyerImageConfig: action.payload
      };
    case 'BUYER_QUOTATION_IMAGES':
      return {
        ...state,
        buyerQuotationImages: action.payload
      };
    case 'BUYER_QUOTATION_DOCUMENTS':
      return {
        ...state,
        buyerQuotationDocuments: action.payload
      };
    case 'SELLER_QUOTATION_DOCUMENTS':
      return {
        ...state,
        sellerQuotationDocuments: action.payload
      };
    case 'SELLER_QUOTATION_IMAGES':
      return {
        ...state,
        sellerQuotationImages: action.payload
      };
    case 'QUOTATION_BUYER_ACTION':
      return {
        ...state,
        quotationBuyerAction: action.payload
      };
    case 'QUOTATION_PRICE':
      return {
        ...state,
        quotationPrice: action.payload
      };
    case 'CLEAR_QUOTATION_DETAIL_DATA':
      return {
        ...state,
        quotationPrice: {},
        quotationFormDetails: [],
        quotationFormValues: {}
      };
    case 'QUOTATION_FORM_VALUES':
      return {
        ...state,
        quotationFormValues: action.payload
      };
    case 'QUOTATION_CONTACT_SALES':
      return {
        ...state,
        contactSales: action.payload
      };
    default:
      return state;
  }
};

export default QuotationReducer;

