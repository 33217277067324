import React from 'react';
import { makeStyles } from '@mui/styles';
import { Backdrop, CircularProgress, Box, alpha } from '@mui/material';
import { appTheme } from '../../../../themes/appTheme';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 1301,
    background: alpha(appTheme.palette.common.light, 0.6),
    color: appTheme.palette.common.dark
  },
}));

export default function Loader() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box >
      <Backdrop className={classes.backdrop} open={true} invisible={true} onClick={handleClose}>
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
      {/**below line just to remove lint issue */}
      {open ? true : false}
    </Box>
  );
}