const initialState = {
  requestInformation: { },
  requestInformationError: { },
};

const RequestInformationReducer = (state = initialState, action) => {
  if (action.type) {
      return state;
  }
};

export default RequestInformationReducer;
