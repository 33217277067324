import React, { useRef, useEffect } from 'react';
import { Box, alpha } from '@mui/material';
import accodionStyles from './accordion.module.scss';
import overlayStyles from './overlay.module.scss';
import DeviceBreakPoints from './../../../deviceBreakPoints';

const CustomAccordion = (props) => {
    const contentRef = useRef();
    const [dynamicHeight, setDynamicHeight] = React.useState(0);
    const [dynamicStyle, setDynamicStyle] = React.useState(overlayStyles);
    const { theme } = DeviceBreakPoints();
    useEffect(() => {
        if (contentRef.current) {
            setDynamicHeight(contentRef.current.scrollHeight);
        }
    }, [props.children]);
    useEffect(() => {
        if (props.type === 'accordion') {
            setDynamicStyle(accodionStyles);
        }
    }, []);

    return (
        <Box sx={{ backgroundColor: 'secondaryBackground.bgBody' , color: 'secondary.main', boxShadow: `0 4px 8px 0 ${alpha(theme.palette.common.dark, 0.3)}, 0 6px 20px 0 ${alpha(theme.palette.common.dark, 0.2)}`, borderColor: 'secondary.light !important' }} className={dynamicStyle['content-parent']} ref={contentRef} style={{ height: props.isOpen ? dynamicHeight : 0 }}>
            <Box className={dynamicStyle['content']}>{props.children}</Box>
        </Box>
    );
};
export default CustomAccordion;