import Images from '../assets/img';
import { DOCS } from '../actions/configuration';

export const productVideosList = [

    {
        id: 1,
        image: Images.playButton,
        link: `${process.env.REACT_APP_CDN_LINK}/ms-public-images/MobilityMarketplaceDemo.mp4`,
        question: 'PRODUCT_VIDEO_TEXT.QUESTION_1',
        solution: 'PRODUCT_VIDEO_TEXT.SOLUTION_1'
    }
];


 export const productGuideList = [
    {
        id: 1,
        link: DOCS.USER_MANUAL,
        question: 'PRODUCT_GUIDE_TEXT.QUESTION_1',
        solution: 'PRODUCT_GUIDE_TEXT.SOLUTION_1'
    },
    {
        id: 2,
        link: DOCS.API_ONBOARDING_MANUAL,
        question: 'PRODUCT_GUIDE_TEXT.QUESTION_2',
        solution: 'PRODUCT_GUIDE_TEXT.SOLUTION_2'
    }
];
