import React, { useEffect, useState } from 'react';
import Headers from '../components/BaseLayout/Header';

const Header = (props) => {
  const [headerKey, resetHeaderKey] = useState(0);
  useEffect(() => {
    const isLoader = localStorage.getItem('resetLoader');
    if (isLoader && isLoader === 'true') {
      resetHeaderKey(1);
    }
    localStorage.removeItem('resetLoader');
  }, [localStorage]);
  return <Headers key={headerKey} {...props} />;
};
export default Header;
