const initialState = {
    categoryDetails: {}
};

const CategoryReducer = (state = initialState, action) => {
  if (action.type === 'UPDATE_CATEGORY_DETAILS') {
    return {
      ...state,
      categoryDetails: action.payload
    };
  } else {
    return state;
  }
};
export default CategoryReducer;