import {
    initialGetInTouchDetailsState,
    initialGetInTouchDetailsErrorState
} from '../data/getInTouchData';

const initialState = {
    getInTouchDetails: { ...initialGetInTouchDetailsState },
    getInTouchDetailsError: { ...initialGetInTouchDetailsErrorState }
};

const GetInTouchReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_IN_TOUCH_DETAILS':
            return Object.assign({}, state, { getInTouchDetails: action.payload });
        case 'GET_IN_TOUCH_DETAILS_ERR':
            return Object.assign({}, state, { getInTouchDetailsError: action.payload });
        default:
            return state;
    }
};

export default GetInTouchReducer;