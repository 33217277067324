const initialState = {
    isRegistrationCheckDone: false, companyDetailsError: '', userCompanyStatus: '', errorUserCompanyStatus: '',
    mismatchInDcsAndMsData: false,
    msCompanyDetails: '',
    profileStatus: {
        companyRegStatusDescription: '',
        companyId: '',
        status: '',
        userId: '',
        statusCheck: false
    }
};

const CompanyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_COMPANY_ERROR':
            return { ...state, companyDetailsError: action.payload };
        case 'IS_REG_CHECK_DONE':
            return { ...state, isRegistrationCheckDone: action.payload };
        case 'USER_COMPANY_STATUS':
            return { ...state, userCompanyStatus: action.payload };
        case 'ERROR_USER_COMPANY_STATUS':
            return { ...state, errorUserCompanyStatus: action.payload };
        case 'MISMATCH_IN_DCS_MS_COMPANY_DATA':
            return { ...state, mismatchInDcsAndMsData: action.payload };
        case 'MS_COMPANY_DETAILS':
            return { ...state, msCompanyDetails: action.payload };
        case 'UPDATE_PROFILE_STATUS':
            return {
                ...state, profileStatus: {
                    companyRegStatusDescription: action.payload.companyRegStatusDescription,
                    companyId: action.payload.companyId,
                    status: action.payload.status,
                    userId: action.payload.userId,
                    statusCheck: true
                }
            };
        default:
            return state;
    }
};
export default CompanyDetailsReducer;