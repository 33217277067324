import React, { useEffect, useState } from 'react';
import HeaderContainer from './header';
import Footer from '../components/BaseLayout/Footer';
import BreadCrumb from '../components/BaseLayout/BreadCrumb';
import ProductGuideVideoComponent from '../components/ProductGuideVideoPage/ProductGuideVideo';

function ProductGuideVideo(props) {

    const [path, setPath] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (window.location.pathname.includes('/product-guides') ){
            setPath('guide');
        } else {
            setPath('video');
        }
    },[window.location.pathname]
    );


    return (
        <React.Fragment>
            <HeaderContainer {...props} />
            <BreadCrumb />
            <ProductGuideVideoComponent {...props} redirected={path} />
            <Footer />
        </React.Fragment>
    );
}

export default ProductGuideVideo;
