
const initialState = {
    proposalList: [],
    responseHeaders: '',
    proposalDetail: [],
    proposalError: '',
};

const MyProposalReducer = (state=initialState, action) => {
    switch(action.type){
        case 'MY_PROPOSAL_DATA':
            return Object.assign({}, state, { proposalList: action.payload, responseHeaders: action.headers });
        case 'PROPOSAL_DOC':
            return Object.assign({}, state, { proposalDetail: action.payload });
        case 'PROPOSAL_ERROR':
            return Object.assign({}, state, { proposalError: action.errorMsg });
        case 'EMPTY_PROPOSAL_DOC':
            return Object.assign({}, state, { proposalDetail: action.payload });
        default:
            return state;
    }
};

export default MyProposalReducer;

