const subscribedApisTabs = [
  {
    name: "Subscribed API's",
    index: 0,
    url: '/myaccount/myapplications/application/subscribed-apis',
    key: 'subscribed-apis'
  },
  {
    name: 'Security',
    index: 1,
    url: '/myaccount/myapplications/application/security',
    key: 'security'
  }
];

export default subscribedApisTabs;