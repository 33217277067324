import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Landing from '../components/Landing';
import {
  getProductCategories,
  getPublishedProducts,
} from '../actions/LandingAction';
import { getCountryList } from '../actions/CountryListAction';
import '../index.css';
import HeaderContainer from '../containers/header';
import Footer from '../components/BaseLayout/Footer';

function LandingContainer(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getCountryList();
  }, []);
  
  return (
    <React.Fragment>
      <HeaderContainer {...props} />
      <Landing {...props} />
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (redux_state) => ({
  productCategories: redux_state.LandingReducer.productCategories,
  registeredCompanyDetails:
    redux_state.CompanyDetailsReducer.registeredCompanyDetails,
  companyDetailsError: redux_state.CompanyDetailsReducer.companyDetailsError,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProductCategories,
      getPublishedProducts,
      getCountryList,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer);
