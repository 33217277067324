import React from 'react';
import '../../../../../src/index.scss';
import { Radio, Box } from '@mui/material';

function StyledRadio(props) {
    return (
        <Radio
            disableRipple
            color='default'
            checkedIcon={<Box component='span' sx={{ borderRadius: '50%', width: '20px', height: '20px', backgroundColor: 'primary.main'}} className={`radio-icon radio-icon-checked`} />}
            icon={<Box component='span' sx={{ borderRadius: '50%', width: '20px', height: '20px', backgroundColor: 'secondary.main', '& input:disabled': {backgroundColor: 'primary.main', },'& input:hover': { boxShadow: 'none', backgroundColor: 'secondary.dark', } }} className={`radio-icon`} />}
            checked={props.checked}
            disabled={props.disabled}
            onChange={props.onChangeCB}
            value={props.value}
            inputProps={props.inputProps}
            {...props}
        />
    );
}


export default StyledRadio;