import React, { createContext, useReducer } from 'react';

export const RootContext = createContext();

const RootContextProvider = props => {
    const initialState = { companyDetailsError: {}, registeredCompanyDetails: {} };
    const [rootContextStore, dispatchContext] = useReducer(rootContextReducer, initialState);
    return (
        <RootContext.Provider value={{ rootContextStore, dispatchContext }}>
            {props.children}
        </RootContext.Provider>
    );
};
export default RootContextProvider;

const rootContextReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_COMPANY_ERROR':
            return { ...state, companyDetailsError: action.payload };
        case 'UPDATE_COMPANY_DETAILS':
            return { ...state, registeredCompanyDetails: action.payload };
        default:
            return state;
    }
};