import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAllProductsCount } from '../actions/LandingAction';
import { bindActionCreators } from 'redux';
import HeaderContainer from './header';
import Footer from '../components/BaseLayout/Footer';
import axios from 'axios';
import loading from '../actions/LoaderAction';
import ListingProducts from '../components/SolutionSearch/ListingProducts/ListingProducts';
import Banner from '../components/SolutionSearch/ListingProducts/Banner/banner';
import { Grid, Stack, Container, Box, InputLabel, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Filters from '../components/SolutionSearch/ListingProducts/Filters/filters';
import styles from './listing-products-container.module.scss';
import images from '../assets/img';
import { sortData } from '../data/appConstants';
import i18next from 'i18next';
import DeviceBreakPoints from '../deviceBreakPoints';
import idConstants from '../data/idConstants';
import { getSubcategories } from '../actions/MasterDataAction';
import BreadCrumb from '../components/BaseLayout/BreadCrumb';
import { hyphenatedStringToCamelCase, replaceSpaceWithHyphenInLowerCaps } from '../utils';

const ListingProductsContainer = (props) => {
  let [count, setCount] = useState(0);
  const [filtersSelected, setFiltersSelected] = useState({});
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder, setSortOrder] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [showNoProduct, setShowNoProduct] = useState(false);
  const [productCountsList, setProductCountsList] = useState([]);
  const { isMediumDevice } = DeviceBreakPoints();
  const productCategoriesStore = useSelector((state) => state?.LandingReducer?.productCategories);
  const [showIndicators, setShowIndicators] = useState(true);


  useEffect(() => {
    if (
      productCountsList?.length === 0 &&
      productCategoriesStore?.length &&
      props?.publishedProductsCounts?.counts &&
      Object.keys(props?.publishedProductsCounts?.counts).length > 0
    ) {
      setProductCountsList(
        productCategoriesStore?.filter((item) =>
          props?.publishedProductsCounts?.counts?.hasOwnProperty(
            item?.categoryId
          )
        )
      );
    }
  }, [productCategoriesStore, props?.publishedProductsCounts]);

  const errorCallback = (error) => {
    setCount(count--);
    if (!count) {
      props.loading(false);
    }
    return Promise.reject(error);
  };
  const handleSortByClose = (event, item) => {
    if (item) {
      if (item.id === 'recent') {
        setSortBy(item.id);
      } else {
        setSortBy('name');
        setSortOrder(item.id);
      }
    }
    setAnchorEl(null);
  };
  const handleSortBy = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSortByClose);
    return () => {
      window.removeEventListener('scroll', handleSortByClose);
    };
  }, []);

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        setCount(count++);
        props?.loading(true);
        return config;
      },
      function (error) {
        errorCallback(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        setCount(count--);
        if (!count) {
          props?.loading(false);
        }
        return response;
      },
      function (error) {
        errorCallback(error);
      }
    );
    props?.getAllProductsCount();
  }, []);

  const lookForASubcategory = (path, index, childCategoryName) => {
    const subCategoryName = path?.split('/')[index];
    let isSubCategoryPresent;
    subCategoryName !== 'listing' &&
      props?.getSubcategories()?.then((subcategories) => {
        isSubCategoryPresent = subcategories?.some(
          (subcategory) =>
            replaceSpaceWithHyphenInLowerCaps(subcategory?.name) ===
            subCategoryName
        );
        (!isSubCategoryPresent || !childCategoryName) &&
          props?.history.push('/page-not-found');
      });
  };

  useEffect(() => {
    const path = window?.location?.pathname;
    const categoryName = path?.split('/')[1];
    if (path !== '/listing') {
      let childCategoryName;
      if (
        [
          replaceSpaceWithHyphenInLowerCaps(
            idConstants.cloudServices.productCategoryName
          ),
          replaceSpaceWithHyphenInLowerCaps(
            idConstants.services.productCategoryName
          )
        ].includes(categoryName)
      ) {
        childCategoryName = hyphenatedStringToCamelCase(path?.split('/')[2]);
        lookForASubcategory(path, 3, childCategoryName);
      } else lookForASubcategory(path, 2, true);

      (!idConstants[hyphenatedStringToCamelCase(categoryName)]?.categoryId ||
        (childCategoryName && !idConstants[childCategoryName]?.categoryId)) &&
        props.history.push('/page-not-found');
    }
  }, []);

  const getSortIcon = () => {
    if (sortBy === 'recent') {
      return images.recentlyAddedIcon;
    } else if (sortBy === 'name' && sortOrder === 'asc') {
      return images.ascIcon;
    } else if (sortBy === 'name' && sortOrder === 'desc') {
      return images.descIcon;
    } else {
      return images.sortByIcon;
    }
  };
  
  return (
    <React.Fragment>
      <HeaderContainer text={props.location.state?.text} />
      <BreadCrumb/>
      <Banner />
      <Box sx={{ backgroundColor: 'secondaryBackground.bgBody' }} className={!isMediumDevice ? styles['reset-sort-grid'] : ''}>
        <Container
          className={isMediumDevice && styles['filter-container']}
        >
          <Grid
            container
            md={12}
            xl={12}
            lg={12}
            className={!isMediumDevice ? styles['sort-grid-container'] : ''}
            justifyContent={isMediumDevice ? 'space-between' : 'flex-end'}
            alignItems='center'
          >
            <Grid item>
              {isMediumDevice && (
                <Stack
                  className={styles['filter']}
                  direction='row'
                  justifyContent='space-around'
                  onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                >
                  <Typography className={styles['filter-section']}>
                    {i18next.t('FILTER_SECTION.FILTER')}
                  </Typography>
                  <Box component='img' src={images.filterIcon} />
                </Stack>
              )}
            </Grid>
            <Grid item>
              <IconButton
                className={styles['no-padding']}
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleSortBy}
                aria-label={i18next.t('REVAMPED_SEARCH_RESULT.OPEN_SHOW_MORE')}
                title={i18next.t('REVAMPED_SEARCH_RESULT.OPEN_SHOW_MORE')}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                size='large'>
                <Grid
                  sx={{
                    backgroundColor: 'primaryBackground.bgBody',
                    color: 'common.dark'
                  }}
                  container
                  className={styles['sort-by-container']}
                  justifyContent='space-between'
                >
                  <InputLabel> {i18next.t('FILTER_SECTION.SORT_BY')}</InputLabel>{' '}
                  <Box component='img'
                    className={styles['sort-icon']}
                    src={getSortIcon()}
                  />
                </Grid>
              </IconButton>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    borderRadius: 0,
                    border: '1px solid lightgray',
                    marginTop: '5px',
                    width: '110px'
                  }
                }}
              >
                {sortData.map((item) => {
                  const isActive = (sortBy === item.id) || (sortBy === 'name' && sortOrder === item.id);
                  return (
                    <MenuItem
                      onClick={(event) => handleSortByClose(event, item)}
                      key={item.id}
                      value={item.id}
                      selected={isActive ? true : false}
                      px={1}
                      sx={{
                        paddingLeft: '9px !important'
                      }}
                    >
                      <Typography
                        variant='smallText'
                        color={isActive ? 'common.light' : 'common.dark'}
                      >
                        {i18next.t(item.name)}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          </Grid>
        </Container>
        {isMediumDevice && (
          <Filters
            showIndicators={showIndicators}
            setShowIndicators={setShowIndicators}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            setSubCategoryList={setSubCategoryList}
            subCategoryList={subCategoryList}
            isFiltersOpen={isFiltersOpen}
            setIsFiltersOpen={setIsFiltersOpen}
          />
        )}
        {isMediumDevice ? (
          <>
            <Grid className={styles['products-grid']} item lg={12} xl={12}>
              <ListingProducts
                showIndicators={showIndicators}
                setShowIndicators={setShowIndicators}
                setFiltersSelected={setFiltersSelected}
                productCountsList={productCountsList}
                setShowNoProduct={setShowNoProduct}
                filtersSelected={filtersSelected}
                sortOrder={sortOrder}
                sortBy={sortBy}
                isCombinedListingPage={true}
              />
            </Grid>
          </>
        ) : (
          <>
            <Box>
              <Container>
                <Grid container className={styles['search-grid-container']}>
                  <Grid className={styles['grid-background']} item lg={3} xl={3}>
                    <Filters   
                      showIndicators={showIndicators}
                      setShowIndicators={setShowIndicators}
                      filtersSelected={filtersSelected}                   
                      setIsFiltersOpen={setIsFiltersOpen}
                      setFiltersSelected={setFiltersSelected}
                      subCategoryList={subCategoryList}
                      setSubCategoryList={setSubCategoryList}
                    />
                  </Grid>
                  <Grid
                    className={`${styles['products-grid']} ${showNoProduct ? styles['no-results-found'] : ''
                      }`}
                    item
                    lg={9}
                    xl={9}
                  >
                    <ListingProducts
                      showIndicators={showIndicators}
                      setShowIndicators={setShowIndicators}
                      setFiltersSelected={setFiltersSelected}
                      productCountsList={productCountsList}
                      setShowNoProduct={setShowNoProduct}
                      filtersSelected={filtersSelected}
                      sortOrder={sortOrder}
                      sortBy={sortBy}
                      isCombinedListingPage={true}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>
        )}
      </Box>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (redux_state) => ({
  publishedProductsCounts: redux_state.LandingReducer.publishedProductsCounts,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllProductsCount,
      loading,
      getSubcategories
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingProductsContainer);
