const initialState = {
    prodBasicInfo: {},
    prodDetailedInfo: {}, allProducts: [], errorInfo: {}, publishErrorInfo: null, authenticationKey: '', validation: {},
    swaggerFilesRes: {}, swaggerFilesErr: null, prodEndErrorInfo: null
};
function camelize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const SolutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_PRODUCT_INFO':
            return Object.assign({}, state, { prodBasicInfo: action.payload1, prodDetailedInfo: action.payload2.productDetailedInfo });
        case 'UPDATE_PRODUCT_INFO':
            return Object.assign({}, state, { prodBasicInfo: action.payload1 });
        case 'GET_ALL_PRODUCTS':
            return Object.assign({}, state, { allProducts: action.payload });
        case 'UPDATE_PRODUCT_DETAILED_INFO':
            return Object.assign({}, state, { authenticationKey: action.payload, errorInfo: {} });
        case 'SAVE_ENDPOINT_INFO':
            return Object.assign({}, state, { prodDetailedInfo: action.payload, prodEndErrorInfo: null });
        case 'RESET_PRODUCT':
            const stateCopy = JSON.parse(JSON.stringify(state));
            stateCopy.prodBasicInfo = {};
            stateCopy.prodDetailedInfo = {};
            stateCopy.errorInfo = {};
            stateCopy.authenticationKey = '';
            return stateCopy;
        case 'VALIDATE_PRODUCT_UNIQUENESS':
            return Object.assign({}, state, { errorInfo: {} });
        case 'VALIDATE_BASE_URL_UNIQUENESS':
            return Object.assign({}, state, { errorInfo: {} });
        case 'VALIDATE_BASE_URL_ERROR':
            return Object.assign({}, state, { errorInfo: action.error });
        case 'SAVE_PRODUCT_DETAILED_INFO':
            return Object.assign({}, state, { prodDetailedInfo: action.payload.prodDetailedInfo, errorInfo: {} });
        case 'REDUX_STORAGE':
            return Object.assign({}, state, { prodBasicInfo: action.payload.prodBasicInfo, prodDetailedInfo: action.payload.prodDetailedInfo, validation: action.payload.validation });
        case 'PRODUCT_INFO_ERROR':
            return Object.assign({}, state, { errorInfo: action.error });
        case 'DELETE_GROUP':
        case 'DELETE_ENDPOINT':
            return Object.assign({}, state, {
                prodDetailedInfo: action.payload
            });
        case 'DELETE_SECRETPARAM':
            return Object.assign({}, state, { prodDetailedInfo: action.payload });
        case 'DELETE_ENDPOINT_PARAMETER':
            return Object.assign({}, state, { prodDetailedInfo: action.payload });
        case 'GET_PRODUCT_DETAILED_INFO':
            const prodBasicInfo = action.payload.prodBasicInfo !== undefined && action.payload.prodBasicInfo !== null ? action.payload.prodBasicInfo : '';
            const prodDetailedInfo = action.payload.productDetailedInfo !== undefined && action.payload.productDetailedInfo !== null ? action.payload.productDetailedInfo : '';
            const businessCategoriesMap = action.payload.prodBasicInfo.productBusinessCategoryMap;
            const businessCategories = [];
            const secretHeaderParamData = [];

            if (prodDetailedInfo.secretHeaderParamData === undefined && prodDetailedInfo.accessControls !== undefined) {
                if (prodDetailedInfo.accessControls.secretHeaders !== undefined) {
                    prodDetailedInfo.accessControls.secretHeaders.map(header => {
                        header.type = 'header';
                        secretHeaderParamData.push(header);
                        header.id = header._id;
                        return true;
                    });
                }
                if (prodDetailedInfo.accessControls.secretParameter !== undefined) {
                    prodDetailedInfo.accessControls.secretParameter.map(parameter => {
                        parameter.type = 'parameter';
                        parameter.id = parameter._id;
                        secretHeaderParamData.push(parameter);
                        return true;
                    });
                }
                prodDetailedInfo.secretHeaderParamData = secretHeaderParamData;
            }

            if (businessCategoriesMap.length > 0) {
                businessCategoriesMap.map(value => {
                    let categoryObj = {};
                    categoryObj = {
                        id: value.businessCategory.businessCategoryId,
                        name: value.businessCategory.businessCategoryName
                    };
                    businessCategories.push(categoryObj);
                    return true;
                });
            }
            prodBasicInfo.businessCategories = businessCategories;
            if (prodDetailedInfo.accessControls !== undefined) {
                if (prodDetailedInfo.accessControls.authDetails !== undefined) {
                    const authDetails = prodDetailedInfo.accessControls.authDetails;
                    prodDetailedInfo.authorizationUrl = authDetails.authorizationUrl ? authDetails.authorizationUrl : '';
                    prodDetailedInfo.leg = authDetails.legs ? authDetails.legs : '';
                    prodDetailedInfo.authenticationUrl = authDetails.authenticationUrl ? authDetails.authenticationUrl : '';
                    prodDetailedInfo.auth = authDetails.authType ? authDetails.authType : '';
                    prodDetailedInfo.authType = authDetails.authType ? authDetails.authType : '';
                    prodDetailedInfo.grantType = authDetails.grantType ? authDetails.grantType : '';
                    prodDetailedInfo.seperator = authDetails.separator ? authDetails.separator : '';
                    prodDetailedInfo.scope = authDetails.scope ? authDetails.scope[0] : '';
                }
            }

            const groupDataResponse = prodDetailedInfo.groupData !== undefined ? prodDetailedInfo.groupData : prodDetailedInfo.endpointGroups;
            const groupData = [];
            if (groupDataResponse !== undefined) {
                if (groupDataResponse.length > 0) {
                    groupDataResponse.map(value => {
                        groupData.push({
                            'name': value.groupName,
                            'description': value.groupDesc,
                            'id': value._id
                        });
                        return true;
                    });
                }
            }
            const endpointsResponse = prodDetailedInfo.endpointsData !== undefined ? prodDetailedInfo.endpointsData : prodDetailedInfo.endPointDetails;
            const endpoints = [];
            if (endpointsResponse !== undefined) {
                if (endpointsResponse.length > 0) {
                    endpointsResponse.map(value => {
                        const headers = [];
                        if (value.headers !== undefined) {
                            value.headers.map(header => {
                                headers.push({
                                    'parameter': 'Header',
                                    'dataType': header.datatype,
                                    'name': header.name,
                                    'value': header.value,
                                    'description': header.description,
                                    'id': header._id,
                                    'required': header.isRequired

                                });
                                return true;
                            });
                        }

                        const parameters = [];
                        if (value.parameters !== undefined) {
                            value.parameters.map(parameter => {
                                parameters.push({
                                    'parameter': 'Query',
                                    'dataType': parameter.datatype,
                                    'name': parameter.name,
                                    'value': parameter.value,
                                    'description': parameter.description,
                                    'id': parameter._id,
                                    'required': parameter.isRequired
                                });
                                return true;
                            });
                        }
                        const pathParameterData = [];
                        if (value.pathParameter !== undefined) {
                            value.pathParameter.map(pathData => {
                                pathParameterData.push({
                                    'Name': pathData.name !== undefined ?pathData.name : '',
                                    'DataType': pathData.datatype !== undefined ? pathData.datatype : ''  ,
                                    'Value': pathData.value !==undefined ? pathData.value :'' ,
                                    'Description': pathData.description!== undefined ? pathData.description : '',
                                    'Required': pathData.isRequired !== undefined ? pathData.isRequired : '',
                                    'id' : pathData._id !== undefined ? pathData._id : '',

                                });
                                return true;
                            });
                        }
                        endpoints.push({
                            'header': headers,
                            'method': value.method !== undefined ? value.method : '',
                            'name': value.name !== undefined ? value.name : '',
                            'id': value._id !== undefined ? value._id : '',
                            'query': parameters,
                            'group': value.group !== undefined ? value.group : '',
                            'description': value.description !== undefined ? value.description : '',
                            'relativePath': value.path !== undefined ? value.path : '',
                            'paramCount': headers.length + parameters.length,
                            'relativePathData': pathParameterData ? pathParameterData : ''

                        });
                        return true;
                    });
                }
            }

            if (prodDetailedInfo) {
                prodDetailedInfo.key = prodDetailedInfo.authorizationKey !== undefined ? prodDetailedInfo.authorizationKey : prodDetailedInfo.key !== undefined ? prodDetailedInfo.key : '';
                prodDetailedInfo.groupData = groupData;
                prodDetailedInfo.endpointsData = endpoints;
            }
            return Object.assign({}, state, { prodBasicInfo: prodBasicInfo, prodDetailedInfo: prodDetailedInfo });
        case 'PUBLISH_API':
            return Object.assign({}, state, { allProducts: action.payload1, publishData: action.payload2, publishErrorInfo: null });
        case 'PUBLISH_ERROR':
            return Object.assign({}, state, { publishErrorInfo: action.error, selectedIndex: action.selectedIndex, errorMsg: action.errorMsg });
        case 'UPLOAD_SWAGGER_FILE':
            const prodInfo = {};
            prodInfo.productName = action.payload.apiName;
            prodInfo.productShortDesc = action.payload.description;
            prodInfo.businessCategories = [];
            prodInfo.productLongDesc = '';
            prodInfo.termsAndConditions = '';
            const prodDetInfo = {};
            prodDetInfo.baseUrl = action.payload.baseUrl ;
            const swaggerGroupDataResponse = action.payload.endpointGroups;
            const swaggerGroupData = [];
            if (swaggerGroupDataResponse !== undefined) {
                if (swaggerGroupDataResponse.length > 0) {
                    swaggerGroupDataResponse.map(value => {
                        swaggerGroupData.push({
                            'name': value.groupName,
                        });
                        return true;
                    });
                }
            }
            const swaggerEndpointsResponse = action.payload.endPointDetails;
            const swaggerEndpoints = [];
            if (swaggerEndpointsResponse !== undefined) {
                if (swaggerEndpointsResponse.length > 0) {
                    swaggerEndpointsResponse.map((value, index) => {
                        const headers = [];
                        if (value.headers !== undefined) {
                            value.headers.map(header => {
                                headers.push({
                                    'parameter': 'Header',
                                    'dataType': header.datatype !== undefined ? camelize(header.datatype) : 'String',
                                    'name': header.name,
                                    'description': header.description,
                                    'required': header.isRequired !== undefined ? (header.isRequired ? '1' : '0'): ''

                                });
                                return true;
                            });
                        }

                        const parameters = [];
                        if (value.parameters !== undefined) {
                            value.parameters.map(parameter => {
                                parameters.push({
                                    'parameter': 'Query',
                                    'dataType': parameter.datatype !== undefined ? camelize(parameter.datatype) : 'String',
                                    'name': parameter.name,
                                    'description': parameter.description,
                                    'required': parameter.isRequired !== undefined ? (parameter.isRequired ? '1' : '0') : ''
                                });
                                return true;
                            });
                        }
                        const pathParameterData = [];
                        if (value.pathParameter !== undefined) {
                            value.pathParameter.map(pathData => {
                                pathParameterData.push({
                                    'Name': pathData.name !== undefined ? pathData.name : '',
                                    'DataType': pathData.datatype !== undefined ? pathData.datatype.toUpperCase() : 'STRING',
                                    'Description': pathData.description !== undefined ? pathData.description : '',
                                    'Required': pathData.isRequired !== undefined ? (pathData.isRequired ? '1' : '0') : ''

                                });
                                return true;
                            });
                        }
                        swaggerEndpoints.push({
                            'header': headers,
                            'method': value.method !== undefined ? value.method : '',
                            'name': value.name !== undefined ? value.name : 'endpoint' + (index+1),
                            'query': parameters,
                            'group': value.group !== undefined ? value.group : '',
                            'description': value.description !== undefined ? value.description : '',
                            'relativePath': value.path !== undefined ? value.path : '',
                            'paramCount': headers.length + parameters.length,
                            'relativePathData': pathParameterData ? pathParameterData : ''

                        });
                        return true;
                    });
                }
            }

            if (prodDetInfo) {
                prodDetInfo.groupData = swaggerGroupData;
                prodDetInfo.endpointsData = swaggerEndpoints;
            }
            return Object.assign({}, state, { prodBasicInfo: prodInfo, swaggerProdDetailedInfo: prodDetInfo, swaggerFilesErr: null });
        case 'UPLOAD_SWAGGER_ERROR':
            return Object.assign({}, state, { swaggerFilesErr: action.error });
        case 'PRODUCT_ENDPOINT_ERROR':
            const prodDetGroup = {};
            prodDetGroup.groupData = action.groupsdata;
            return Object.assign({}, state, { prodDetailedInfo: prodDetGroup, prodEndErrorInfo: action.error });

        default:
            return state;
    }
};

export default SolutionReducer;