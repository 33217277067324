import images from '../assets/img';

export const categoryLogoData = {
    api:
    {
        '10227cff-ffa6-4052-bf21-c5e5c054e7c9': images.categoryAssets.iotLogo,
        '2e8da7c9-c160-444a-9afd-57541beb72f9': images.categoryAssets.journeyRouteLogo,
        '49805a5c-c91b-483b-9353-d8a12e2d2281': images.categoryAssets.parkingLogo,
        '5ff1cfc6-8d9d-402d-9199-8aa1268941f6': images.categoryAssets.verificationLogo,
        '6aee29d6-48b9-4084-8c41-c15b2fd22442': images.categoryAssets.logisticsAndFleetLogo,
        '848cdd5c-9599-4367-9e80-5f0da8884e41': images.categoryAssets.fintechLogo,
        'd2dbf424-2171-4a1d-9fcb-1603c120fd7e': images.categoryAssets.locationAndMappingLogo,
        'e3468588-2592-4856-b041-ded9752280f7': images.categoryAssets.digitalIndiaLogo,
        'eeb29e20-d74d-48fe-b680-4cc2e06dfb1a': images.categoryAssets.fuelChargingLogo,
        '8a3de754-e3aa-472a-8fca-870ae708d864': images.categoryAssets.smartCityLogo,
        'a5f9312e-65b3-499a-8fad-00c716be3f59': images.categoryAssets.environmentLogo
    },
    solutions:
    {
        '8642e17b-ed6d-49f7-866c-7a8c893a9725': images.categoryAssets.logisticsAndFleetLogo,
        '10227cff-ffa6-4052-bf21-c5e5c054e7c9': images.categoryAssets.iotLogo,
        'ecfa4371-f357-4764-a6d4-7944a11e5c4f': images.categoryAssets.parkingLogo
    },
    data:
    {
        '075e8ee4-c286-4802-b88c-6c0f075e4b48': images.categoryAssets.vehicleAnalyticsLogo
    },
    hardware:
    {
        '5ca2bef1-918c-46d9-a520-4eefe9d381b3': images.categoryAssets.displaySystemsLogo,
        '3b9dc11f-8d27-4b97-9b59-16a06b1094ad': images.categoryAssets.sensorsLogo,
        'da26519a-0590-4241-a96b-53ad7d1fd479': images.categoryAssets.accessControlLogo,
        '7860191d-ce2a-4649-a416-bcf99e23ee9a': images.categoryAssets.mechanicalDeviceLogo,
        'f31f2610-8897-494e-9135-1a2ac2d06e9d': images.categoryAssets.specialityHardwareLogo,
        '180d7f66-f758-43ae-8f60-1fc3503cd690': images.categoryAssets.parkingLogo,
        '0d7188b9-8170-46ff-9241-127020c25711': images.categoryAssets.communicationServicesLogo,
        '0130d1eb-da5f-40ee-8479-6bf3d60506fb': images.categoryAssets.telematicsLogo,
        'f7076d6a-3de3-478c-a3e2-c3ffa42b0244': images.categoryAssets.adasHardwareLogo
    },
    managedservices:
    {
        'aa69eea7-b7e7-4977-a18f-0218d1f92aad': images.categoryAssets.azureLogo,
        'ff520dc9-c0ae-4883-b236-2dfde7a6e30f': images.categoryAssets.awsLogo,
        '8b9f536b-6156-4b01-85f0-b9e7c6523419': images.categoryAssets.cloudLogo,
        'eac14c5b-bcde-421e-844d-7747f1016fd6': images.categoryAssets.cloudManagedServicesLogo,
        '5cd5f5fb-d29f-4cc9-817d-a2144a4c7985': images.categoryAssets.gcpLogo
    },
    cloudinfrastructure:
    {
        'aa69eea7-b7e7-4977-a18f-0218d1f92aad': images.categoryAssets.azureLogo,
        'ff520dc9-c0ae-4883-b236-2dfde7a6e30f': images.categoryAssets.awsLogo,
        '8b9f536b-6156-4b01-85f0-b9e7c6523419': images.categoryAssets.cloudLogo,        
        '5cd5f5fb-d29f-4cc9-817d-a2144a4c7985': images.categoryAssets.gcpLogo
    },
    enterpriselicensing: {
        'c5cbedfb-3f7f-4ee2-9574-388ecd6e9e84': images.categoryAssets.databaseLogo
    },
    communicationservices: {
        'c3086547-ec6a-4de6-8179-96452e929066': images.categoryAssets.whatsAppLogo,
        '131c3009-6d39-4b8e-b9d1-289595178731': images.categoryAssets.smsLogo,
        '79c617a3-65f5-4b49-b035-e2355d000c41': images.categoryAssets.emailLogo
    },
    softwareservices:
    {
        '10227cff-ffa6-4052-bf21-c5e5c054e7c9': images.categoryAssets.iotLogo,
        '2e8da7c9-c160-444a-9afd-57541beb72f9': images.categoryAssets.journeyRouteLogo,
        '49805a5c-c91b-483b-9353-d8a12e2d2281': images.categoryAssets.parkingLogo,
        '5ff1cfc6-8d9d-402d-9199-8aa1268941f6': images.categoryAssets.verificationLogo,
        '6aee29d6-48b9-4084-8c41-c15b2fd22442': images.categoryAssets.logisticsAndFleetLogo,
        '848cdd5c-9599-4367-9e80-5f0da8884e41': images.categoryAssets.fintechLogo,        
        'e3468588-2592-4856-b041-ded9752280f7': images.categoryAssets.digitalIndiaLogo,
        'eeb29e20-d74d-48fe-b680-4cc2e06dfb1a': images.categoryAssets.fuelChargingLogo,
        '8a3de754-e3aa-472a-8fca-870ae708d864': images.categoryAssets.smartCityLogo,
        'a5f9312e-65b3-499a-8fad-00c716be3f59': images.categoryAssets.environmentLogo,
        '9c84a63a-edf2-4c38-b868-33e2a99c832a': images.categoryAssets.anprLogo,
        '614ea433-7ac6-4699-9d08-59b3ce735f25': images.categoryAssets.parkingPaymentLogo,
        'da26519a-0590-4241-a96b-53ad7d1fd479': images.categoryAssets.accessControlLogo
    },
    professionalservices:
    {
        '0130d1eb-da5f-40ee-8479-6bf3d60506fb': images.categoryAssets.telematicsLogo
    },
};

