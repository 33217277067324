const initialState = {
    isAccountDeletable: {}, requestAccDeletionResp: {}, accountDeletionError: '', errorDeleteAccount: ''
};

const DeleteAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ACCOUNT_DELETION_CHECK':
            return Object.assign({}, state, { isAccountDeletable: action.payload });
        case 'ACCOUNT_DELETION_REQUEST':
            return Object.assign({}, state, { requestAccDeletionResp: action.payload });
        case 'ACCOUNT_DELETION_ERROR':
            return Object.assign({}, state, { accountDeletionError: action.error });
        case 'RESET_ACCOUNT_DELETION':
            return Object.assign({}, state, { accountDeletionError: '', isAccountDeletable: {}, requestAccDeletionResp: {} });
        case 'ERROR_DELETE_ACCOUNT':
            return { ...state, errorDeleteAccount: action.payload };
        default:
            return state;
    }
};

export default DeleteAccountReducer;