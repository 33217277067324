const camelCase = (inputText) => {
  const replaceText = /(?:^\w|[A-Z]|\b\w|\s+)/g;
  return inputText.replace(replaceText, function (camelCaseMatch, index) {
    if (+camelCaseMatch === 0) return '';
    return index === 0
      ? camelCaseMatch.toLowerCase()
      : camelCaseMatch.toUpperCase();
  });
};
export default camelCase;
