import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import styles from './ie-alert.module.scss';
import { appTheme } from '../../themes/appTheme';

const stylesIEAlert = {
  root: {
    color: appTheme.palette.common.dark,
    backgroundColor: appTheme.palette.warning.main
  }
};

const IEAlert = (props)=> {
  const [open, setOpen] = React.useState('true');
  const { classes } = props;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={120000}
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        message={i18next.t('IEAlertMessage')}
        action={action}
        className={styles['snackbar-container']}
        ContentProps={{
          className: styles['snackbar-content'],
          classes:{root: classes.root}
        }}
      />
    </React.Fragment>
  );
};
export default withStyles(stylesIEAlert)(IEAlert);