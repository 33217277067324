import React from 'react';
import ProductGuideCard from './ProductGuideCard';
import ProductVideoCard from './ProductVideoCard';
import styles from './product-card-grid.module.scss';
import Box from '@mui/material/Box';

const ProductCardGrid = (props) => {
    return (
        <Box className={styles['grid-container']} >
            {
                props?.redirected === 'video' ? <ProductVideoCard /> : <ProductGuideCard />
            }
        </Box>
    );
};

export default ProductCardGrid;
