const initialState = {
    error : null
};

const ErrorReducer = (state = initialState, action) => {
  if (action.type === 'ERROR') {
    return Object.assign({}, state, { error: action.error });
  } else {
    return state;
  }
};

export default ErrorReducer;