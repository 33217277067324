import React from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import './customer-journey.scss';
import Images from '../../../assets/img';
import i18next from 'i18next';
import DeviceBreakPoints from '../../../deviceBreakPoints';

const sellerSteps = () => {
    const { isMediumDevice } = DeviceBreakPoints();
    return (
        <Grid container>
            <Grid item xs sm md lg xl>
                <Box className='number-bold-font'><Typography variant='boldOnlyText'>1.</Typography></Box>
                <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.REGISTER_AS_SELLER')}</Typography></Box>
            </Grid>
            <Grid item xs sm md lg xl>
                <Box className='number-bold-font'><Typography variant='boldOnlyText'>2.</Typography></Box>
                <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'} >{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.LIST_PRODUCT')}</Typography></Box>
            </Grid>
            <Grid item xs sm md lg xl>
                <Box className='number-bold-font'><Typography variant='boldOnlyText'>3.</Typography></Box>
                <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.PUBLISH')}</Typography></Box>
            </Grid>
        </Grid>
    );
};

const buyerSteps = () => {
    const { isMediumDevice } = DeviceBreakPoints();
    return (
        <Grid container>
            <Grid item xs sm md lg xl>
                <Box className='number-bold-font'><Typography variant='boldOnlyText'> 1.</Typography></Box>
                <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.SEARCH_PRODUCT')}</Typography></Box>
            </Grid>
            <Grid item xs sm md lg xl>
                <Box className='number-bold-font'><Typography variant='boldOnlyText'> 2.</Typography></Box>
                <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.CHOOSE_PRODUCT')}</Typography></Box>
            </Grid>
            <Grid item xs sm md lg xl>
                <Box className='number-bold-font'><Typography variant='boldOnlyText'> 3.</Typography></Box>
                <Box><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.SUBSCRIBE_USE')}</Typography></Box>
            </Grid>
        </Grid>
    );
};

const elementBlockToDisplay = (value) => {
    const { theme } = DeviceBreakPoints();
    return (
        <Box className='diagonal-bg' sx={{ backgroundImage: `linear-gradient(191.3deg, ${theme.palette.primaryBackground.main} 50%, ${theme.palette.secondaryBackground.main} 50%)`, color:'common.light' }}>
            <Box my={2} textAlign='center'>
            <Box component='span' className='initial-bold-font'><Typography variant='h6' component='boldText' sx={{fontSize: '1.8rem'}}>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.HOW_IT_WORKS')}</Typography></Box>
            </Box>
            <Box textAlign='center' mt={6} mx={value === 'apply-margin' && 2} className='stepper-text'>
                {sellerSteps()}
            </Box>
            <Box textAlign='center'>
                <Box component='img' src={Images.sellerStepper} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.SELLER_STEPPER')} className='seller-stepper' />
            </Box>
            <Box mr={1} display='flex' justifyContent='flex-end'>
                <Box component='img' src={Images.guyWithIpadMobileView} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.GUY_WITH_IPAD_MOBILE_VIEW')} className='guy-with-ipad-img-style' />
            </Box>
            <Box ml={1}>
                <Box component='img' src={Images.girlWithIpad} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.GIRL_WITH_IPAD')} className='girl-with-ipad-style' />
            </Box>
            <Box textAlign='center' mx={2} mt={2} className='stepper-text'>
                {buyerSteps()}
            </Box>
            <Box textAlign='center'>
                <Box component='img' src={Images.buyerStepper} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.BUYER_STEPPER')} className='buyer-stepper' />
            </Box>
        </Box>
    );
};

function CustomerJourney() {
    const { theme } = DeviceBreakPoints();
    return (
        <>
            <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                <Box className='diagonal-bg' sx={{ backgroundImage: `linear-gradient(184.2deg, ${theme.palette.primaryBackground.main} 50%, ${theme.palette.secondaryBackground.main} 50%)`, color:'common.light' }}>
                    <Container>
                    <Box textAlign='center' py={3}><Typography variant='boldText' className='initial-bold-font'>{i18next.t('HOME_PAGE.CUSTOMER_JOURNEY.HOW_IT_WORKS')}</Typography></Box>
                        <Grid container className='upper-section-wrapper'>
                            <Grid item xs sm md={2} lg={2} xl={2}>
                                <Box component='img' src={Images.guyWithIpad} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.GUY_WITH_IPAD_MOBILE_VIEW')} className='guy-with-ipad-img-style' />
                            </Grid>
                            <Grid item xs sm md={6} lg={6} xl={6} className='step-section-wrapper'>
                                {sellerSteps()}
                                <Box component='img' src={Images.sellerStepper} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.SELLER_STEPPER')} className='seller-stepper' />
                            </Grid>
                            <Grid item xs sm md={1} lg={1} xl={1}>
                                <Box className='arrow-right upper-arrow-placement'></Box>
                            </Grid>
                            <Grid item xs sm md={2} lg={2} xl={2}>
                                <Box display='inline-block'><Box component='img' src={Images.desktop} className='upper-desktop' /></Box>
                            </Grid>
                        </Grid>
                        <Grid container className='bottom-section-wrapper'>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Box component='img' src={Images.desktop} className='upper-desktop' />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <Box className='arrow-right bottom-arrow-placement'></Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{}}>
                                {buyerSteps()}
                                <Box component='img' src={Images.buyerStepper} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.BUYER_STEPPER')} className='buyer-stepper' />
                            </Grid>

                            <Grid item xs sm md={2} lg={2} xl={2}>
                                <Box display='inline-block'><Box component='img' src={Images.girlWithIpad} alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.GIRL_WITH_IPAD')} className='girl-with-ipad-style' /></Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box display={{ xs: 'none', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
                {elementBlockToDisplay()}
            </Box>
            <Box display={{ xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' }}>
                {elementBlockToDisplay('apply-margin')}
            </Box>
        </>
    );
}

export default CustomerJourney;
