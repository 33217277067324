import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
  Grid, Box
} from '@mui/material';
import i18next from 'i18next';
import styles from './banner.module.scss';
import images from '../../../../assets/img';
import DeviceBreakPoints from '../../../../deviceBreakPoints';
import { IMAGE_CDN } from '../../../../actions/configuration';

const Banner = () => {
  const { isXlDevice } = DeviceBreakPoints();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className={styles['banner-container']} sx={{ backgroundImage: `url(${IMAGE_CDN}revamped-homepage-banner-background.png)`, backgroundColor: 'primaryBackground.banner' }}>
      <Stack
        alignItems={'center'}
        justifyContent='center'
        direction={'row'}
        className={
          isXlDevice ? styles['padding-stack-xl'] : styles['padding-stack']
        }
      >
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Stack
            justifyContent='center'
            direction={'column'}
          >
            <Typography
              component='h1'
              variant='bannerTitle'>
              {i18next.t('SEARCH_RESULT_CONTAINER_TEXT.PLUG_PLAY')}
            </Typography>
            <Typography
              variant='bannerSubTitle'
            >
              {i18next.t('SEARCH_RESULT_CONTAINER_TEXT.FROM_APIS')}.
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          className={styles['align-image-right']}
        >
          <Box component='img' src={images.listingPageBanner} className={styles['image']} />
        </Grid>
      </Stack>
    </Container>
  );
};
export default Banner;
