const initialState = {
    usageTracking: [],
    userApplications: [],
    apiDataMsg: {},
    usageMetrics: [],
    allSubscriptions: [],
    responseHeaders: '',
};


const ApiStatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USAGE_TRACKING':
            return Object.assign({}, state, { usageTracking: action.payload, apiDataMsg: {}, responseHeaders: action.headers });
        case 'GET_USER_APPLICATIONS':
            return Object.assign({}, state, { userApplications: action.payload });
        case 'API_DATA':
            return Object.assign({}, state, { apiDataMsg: action.errorMsg, usageTracking: {} });
        case 'GET_USAGE_METRICS':
            return Object.assign({}, state, { usageMetrics: action.payload });
        case 'ALL_SUBSCRIPTIONS':
            return Object.assign({}, state, { allSubscriptions: action.payload });
        default:
            return state;
    }

};

export default ApiStatisticsReducer;
