import axios from 'axios';
import * as Constants from './configuration';

export function getCountryList() {
  return (dispatch) =>
    axios
      .get(
        Constants.REGISTER_USER_ACTION.GET_COUNTRY_LIST,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'COUNTRY_LIST',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
};



