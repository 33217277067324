import { createContext } from 'react';

const categoryDetails = {
    subCategories: [],
    subCategoryCounts: [],
    latestProducts: [],
    latestProductsCount: '',
    popularProducts: [],
    popularProductsCount: '',
    categoryId: '',
    categoryName: '',
    maxCardsLength: 11
};

const CategoryDetailsContext = createContext(categoryDetails);

export { CategoryDetailsContext, categoryDetails };