const initialState = {
    serviceDefinitionList: [],
    apiGuide: {}
};
const ApiGuideReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SERVICE_DEFINITION_LIST':
            return Object.assign({}, state, { serviceDefinitionList: action.payload });
        case 'SET_API_GUIDE':
            return Object.assign({}, state, { apiGuide: action.payload });
        default:
            return state;
    }
};

export default ApiGuideReducer;