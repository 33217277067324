const initialState = {
    sellerNames: []
};
const SellerNamesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SELLER_NAMES':
            return {
                sellerNames: action.payload
            };
        case 'CLEAR_SELLER_NAMES':
            return {
                sellerNames: []
            };
        default:
            return state;
    }
};

export default SellerNamesReducer;